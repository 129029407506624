/* eslint-disable react/prop-types */
import React from 'react'

const Bake = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Bake"
    viewBox="0 0 720 720"
  >
    <path fill="#f3ddce" d="M0 0h720v720H0z" />
    <path
      fill="none"
      stroke="#bf8d6d"
      strokeMiterlimit="10"
      strokeWidth="3"
      d="M153.56 358.72c-.63 100.66 214.92 12 214.92 12s-215.66 144.13-214.92-12 215-2.71 215-2.71-214.34-101.87-215 2.71z"
    />
    <path
      fill="none"
      stroke="#bf8d6d"
      strokeMiterlimit="10"
      strokeWidth="3"
      d="M368.5 358.94s-207.37-48.12-214.94-.22c-6.52 41.3 214.93 9.11 214.93 9.11M153.56 358.72l226.84 5"
    />
    <path
      fill="#af6e3e"
      d="M367.05 347.87l-.05 31.76 143.63 15c30.93-.18 55.92-14.61 56-32.45s-24.91-32.35-55.84-32.64z"
    />
  </svg>
)

export { Bake }
