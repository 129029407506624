/* eslint-disable react/prop-types */
import React from 'react'

const Play = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Play"
    viewBox="0 0 720 720"
  >
    <path fill="#af6e3e" d="M0 .3h720v720H0z" />
    <rect
      width="128.55"
      height="128.55"
      x="228.54"
      y="363.03"
      fill="#f3ddce"
      rx="9.92"
    />
    <path
      fill="#af6e3e"
      d="M309.62 458.5c-.4-2.11-.71-3.92-.91-5.44a34.13 34.13 0 01-.31-4.67 24.25 24.25 0 01-8.72 8.08 25.6 25.6 0 01-12.89 3.2q-8.84 0-13.69-4.7t-4.86-12.74q0-8.06 7.05-13.06t18.34-5h14.77v-7.44q0-6.45-4.08-10.11T292.79 403q-6.82 0-11 3.33a10.3 10.3 0 00-4.2 8.39h-6.16l-.12-.33q-.33-6.78 5.81-11.92t16-5.14q9.89 0 15.88 5.06t6 14.5v29.55a51.8 51.8 0 00.36 6.17 38.13 38.13 0 001.2 5.94zm-22.1-4.72a23.8 23.8 0 0013.27-3.64 18.12 18.12 0 007.61-9.36v-11.5h-14.83q-8.11 0-13.39 3.86t-5.27 9.31a10.63 10.63 0 003.36 8.22q3.36 3.11 9.25 3.11z"
    />
    <rect
      width="128.55"
      height="128.55"
      x="362.9"
      y="363.03"
      fill="#f3ddce"
      rx="9.92"
    />
    <path
      fill="#af6e3e"
      d="M427.93 454.06a18 18 0 0011.3-3.78 12.5 12.5 0 004.92-10.5h5.94l.11.33a16.77 16.77 0 01-6.55 13.95 23.93 23.93 0 01-15.72 5.61q-12 0-18.7-8.45t-6.69-21.6v-2.34q0-13.11 6.67-21.55t18.66-8.45q9.66 0 16.11 5.84t6.22 15.33l-.11.33h-5.94q0-7.21-4.72-11.52a16.54 16.54 0 00-11.56-4.26q-9.45 0-14.05 6.94t-4.61 17.39v2.34q0 10.61 4.61 17.52t14.11 6.87z"
    />
    <rect
      width="128.55"
      height="128.55"
      x="297.71"
      y="229.02"
      fill="#f3ddce"
      rx="9.92"
    />
    <path
      fill="#af6e3e"
      d="M389.18 307.1q0 13.5-6.11 21.53t-16.83 8a23.34 23.34 0 01-11.36-2.63 20.79 20.79 0 01-7.81-7.53l-1.11 9h-5.22v-86.64h6.67v35.77a21.45 21.45 0 017.66-7.63 21.71 21.71 0 0111.06-2.7 19.61 19.61 0 0116.94 8.64q6.1 8.64 6.11 23zm-6.72-1.16q0-11.51-4.45-18.7a14.5 14.5 0 00-13.16-7.19q-6.89 0-11.11 3.36a20.43 20.43 0 00-6.33 8.69v27.5A17.75 17.75 0 00365 330.88q8.71 0 13.11-6.45t4.39-17.33z"
    />
  </svg>
)

export { Play }
