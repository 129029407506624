/* eslint-disable react/prop-types */
import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/seo'

import Heading2 from '../components/elements/heading2'
import Heading3 from '../components/elements/heading3'
import {
  Bake,
  Cook,
  Craft,
  Design,
  Play,
} from '../../content/assets/icons/makeBlocks'
import { ShopCS } from '../../content/assets/icons/makeBlocksComingSoon'
import MakesCard from '../components/makesCard'
import LatestsPostsGrid from '../components/layout/latestsPostsGrid'
import Bio from '../components/Bio'
import BlogContainer from '../components/layout/blogContainer'
import { LindsMakes } from '../../content/assets/icons/lindsMakes'

const HomePage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMdx.edges

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Home" keywords={[`blog`, `crafts`, `baking`, `cooking`]} />
      <div
        className="bg-repeat bg-center"
        style={{
          backgroundImage:
            "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23af6e3e' fill-opacity='0.08'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E\")",
        }}
      >
        <div className="flex flex-col items-center">
          <div className="mt-20 md:mt-32 w-10/12 max-w-xs sm:max-w-sm md:max-w-lg lg:max-w-2xl">
            <LindsMakes />
          </div>
          <h1 className="mt-4 pb-10 text-xl font-thin text-cocoa">
            creating one day at a time
          </h1>
        </div>
        <ul className="mt-10 mx-auto max-w-screen-xl pb-20 grid grid-cols-square-2 grid-rows-square-3 md:grid-cols-square-2-sm lg:grid-cols-square-3 lg:grid-rows-square-2 gap-6 sm:gap-10 md:gap-12 lg:gap-12 ">
          <li className="col-start-2 row-start-1 lg:col-start-2 lg:row-start-1">
            <Link to="/bake">
              <MakesCard>
                <Bake />
              </MakesCard>
              <div className="text-center">
                <Heading3>Bake</Heading3>
              </div>
            </Link>
          </li>
          <li className="col-start-3 row-start-1 lg:col-start-3 lg:row-start-1">
            <Link to="/craft">
              <MakesCard>
                <Craft />
              </MakesCard>
              <div className="text-center">
                <Heading3>Craft</Heading3>
              </div>
            </Link>
          </li>
          <li className="col-start-3 row-start-2 lg:col-start-4 lg:row-start-1">
            <Link to="/design">
              <MakesCard>
                <Design />
              </MakesCard>
              <div className="text-center">
                <Heading3>Design</Heading3>
              </div>
            </Link>
          </li>
          <li className="col-start-2 row-start-2 lg:col-start-2 lg:row-start-2">
            <Link to="/play">
              <MakesCard>
                <Play />
              </MakesCard>
              <div className="text-center">
                <Heading3>Play</Heading3>
              </div>
            </Link>
          </li>
          <li className="col-start-2 row-start-3 lg:col-start-3 lg:row-start-2">
            <Link to="/cook">
              <MakesCard>
                <Cook />
              </MakesCard>
              <div className="text-center">
                <Heading3>Cook</Heading3>
              </div>
            </Link>
          </li>
          <li className="col-start-3 row-start-3 lg:col-start-4 lg:row-start-2">
            <Link to="/shop">
              <MakesCard>
                <ShopCS />
              </MakesCard>
              <div className="text-center">
                <Heading3>Shop</Heading3>
              </div>
            </Link>
          </li>
        </ul>
        <div className="text-center">
          <Heading2>THE LATEST</Heading2>
          <p className="mt-3 max-w-2xl mx-auto text-lg md:text-xl leading-7 text-gray-500 sm:mt-4">
            Jump straight into a recent post
          </p>
        </div>
        <LatestsPostsGrid posts={posts} />
        <BlogContainer>
          <Bio />
        </BlogContainer>
      </div>
    </Layout>
  )
}

export default HomePage

export const homePageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
      }
    }
    logo: file(absolutePath: { regex: "/LINDS_MAKES.png/" }) {
      childImageSharp {
        fluid(maxWidth: 720) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }, limit: 3) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 720) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            featuredImageAlt
            featureText
            category
            tags
          }
        }
      }
    }
  }
`
