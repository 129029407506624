/* eslint-disable react/prop-types */
import React from 'react'

const Design = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Design"
    viewBox="0 0 720 720"
  >
    <path fill="#f3ddce" d="M.05.61h720v720H.05z" />
    <rect
      width="298.1"
      height="203.29"
      x="211"
      y="224.94"
      fill="#af6e3e"
      rx="22.99"
    />
    <rect
      width="257.33"
      height="147.51"
      x="231.38"
      y="244.79"
      fill="#f3ddce"
      rx="6.72"
    />
    <rect
      width="45.94"
      height="10.75"
      x="337.08"
      y="405.64"
      fill="#f3ddce"
      rx="5.38"
    />
    <path
      fill="#af6e3e"
      d="M335.53 432.74h51.52l20.46 63.53h-90.03l18.05-63.53zM313.89 334.82a7.93 7.93 0 00.5 3.18 2.12 2.12 0 001.95 1.4c6.67 0 23.77-27.44 23.82-27.53a1.78 1.78 0 011.36-1c.5 0 .86.37.86 1-.05.09-18 28.08-26.18 28.08a3.2 3.2 0 01-2.94-2.09 10.53 10.53 0 01-.64-4.26c0-11.89 8.8-39.29 14.83-47.59a44.64 44.64 0 014.13-6c1.41-1.59 2.68-2.5 3.72-2.5h.05a2 2 0 011.72 1.5 5.23 5.23 0 01.27 1.77c0 8.16-12.92 27.31-18.41 31-3.36 10.79-5.04 18.64-5.04 23.04zm21.73-54.62a1.83 1.83 0 00-.09-.54c-.14-.27-.23-.41-.32-.41-.46 0-1.27.73-2.32 1.91a39.11 39.11 0 00-3.94 5.76c-2.82 4.81-6.45 14.83-8.58 20.82 2.77-2.59 15.25-22.74 15.25-27.54z"
    />
    <path
      fill="#af6e3e"
      d="M344.2 307.15a1.46 1.46 0 01-.09-.59 1 1 0 01.41-.86 1.09 1.09 0 01.72-.27 1.7 1.7 0 01.45.09 3 3 0 011.64 2.26c1 1.14 2.54 2.09 5.58 2.32 1.59.13 3 .18 4.31.18a25.22 25.22 0 007.26-.77h.22a.79.79 0 01.77.86 1 1 0 01-.63 1 31.11 31.11 0 01-5.54.37c-1.63 0-3.63-.09-6-.28a16 16 0 01-5.49-1.27c-.05 3.91-1.5 10-4.9 12.43a4.09 4.09 0 01-2.13.68 2.24 2.24 0 01-1-.18c-1.32-.54-1.77-1.86-1.77-3.4a15.89 15.89 0 011.36-5.76c.72-1.63 3.17-5.58 4.9-6.67a.31.31 0 01-.07-.14zM341 315a15.89 15.89 0 00-1.09 4.86c0 .77.13 1.36.63 1.54a.66.66 0 00.28 0c2.13 0 4.76-9.75 4.76-12.24v-.28a1.81 1.81 0 01-.32-.27c-1.7 1.31-3.47 4.44-4.26 6.39z"
    />
    <path
      fill="#af6e3e"
      d="M372.37 311.23a1.68 1.68 0 00-.18-.77c-.63.68-1.31 1.41-2 2.13-3.22 3.54-6.57 7.17-8.75 7.9a3.58 3.58 0 01-1.09.18 2.11 2.11 0 01-1-.23 1.57 1.57 0 01-.86-1.5 5.23 5.23 0 01.09-.72 8.6 8.6 0 011.09-2.13c2.09-3.18 6.71-7.63 9.62-8.08a7.38 7.38 0 011.36-.09 4.43 4.43 0 011.13.09c2.18-2.27 4.18-4 5.58-4a1.54 1.54 0 01.5.09c.95.32 1.5 1.27 1.5 3.08a14.29 14.29 0 01-.27 2.54c-.41 2.27-.73 4-1.5 7a5.54 5.54 0 00-.23.64c4.36-4.63 11.75-12 16.79-14.25a4 4 0 011.49-.41 1 1 0 011.09 1.14 4.28 4.28 0 01-1 2.45 3.54 3.54 0 013 2.58c1.77 1.27 4.76 1.68 7.8 1.68a46.15 46.15 0 008.35-.82h.23a.77.77 0 01.77.87 1.07 1.07 0 01-.64 1 34 34 0 01-5.85.41c-3.77 0-8-.36-10.3-1.23a5.56 5.56 0 01.05.91c0 3.54-1.59 7.85-3.59 9.48a5.38 5.38 0 01-3.44 1.73c-1.55 0-2.64-1.32-2.82-3.45v-1.23c0-4 1.59-7 3.9-10.48-.5-.4-.86-.77-.86-1.08a.38.38 0 01.13-.28c.23-.27.28-.72.64-.72a3 3 0 011 .32c.09-.19.18-.32.23-.46a4.34 4.34 0 00.59-1.27v-.14c0-.05 0-.13-.13-.13a5.47 5.47 0 00-1.23.45c-6.8 3.09-16.1 15.47-17.42 17.33l-.86 3.13c-1.86 6.31-4.85 14.93-6.85 20.14-.54 1.55-4.76 12.84-7.39 12.84a.88.88 0 01-.32 0c-1.27-.41-1.59-1.73-1.59-3.13a17.29 17.29 0 01.55-3.72 78.35 78.35 0 013.72-10.21c2.31-5.22 6.85-14.2 10.34-19a5.34 5.34 0 00.18-.59h-.09s.09-.09.18-.22c.5-1.46 1-2.5 1.45-4 .77-2.95 1.36-5.62 1.77-7.89a21 21 0 00.36-3v-.28h-.09c-.68 0-2.13 1.32-3.95 3.18a3.78 3.78 0 01.73 2.36 5.88 5.88 0 01-.09 1l-1.86-.68a3.27 3.27 0 00.04-.46zm-.37 18.06c-1.9 3.26-4.67 9.43-6.12 12.65a79.46 79.46 0 00-3.67 10.06 16 16 0 00-.77 4c.86-.77 3.67-5.76 6-12 1.44-3.69 3.21-10.49 4.56-14.71zm-9.61-12a21.49 21.49 0 007.89-7.12 46.83 46.83 0 00-7.93 7.14zm32.11-8.66a21.92 21.92 0 00-3.17 10.61v.23c0 .86.18 1.63.77 1.63.41 0 1-.32 1.81-1.13 1.68-1.55 3.45-6.31 3.45-9.57v-.55a18 18 0 01-2.9-1.2z"
    />
  </svg>
)

export { Design }
