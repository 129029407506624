/* eslint-disable react/prop-types */
import React from 'react'

const Craft = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Craft"
    viewBox="0 0 720 720"
  >
    <path fill="#af6e3e" d="M.02.05h720v720H.02z" />
    <path
      fill="#f3ddce"
      d="M487.59 313L203.46 421.74s6.88 25.05 203.92-44.91c0 0 31.82-4.23 20-25.62l74.33-30.79z"
    />
    <ellipse
      cx="477.71"
      cy="294.72"
      fill="#af6e3e"
      stroke="#f3ddce"
      strokeMiterlimit="10"
      strokeWidth="14"
      rx="38.87"
      ry="31.25"
    />
    <path
      fill="#f3ddce"
      d="M487.59 407.14L203.46 298.35s6.88-25.05 203.92 44.91c0 0 31.82 4.23 20 25.62l74.34 30.79z"
    />
    <ellipse
      cx="477.71"
      cy="425.37"
      fill="#af6e3e"
      stroke="#f3ddce"
      strokeMiterlimit="10"
      strokeWidth="14"
      rx="38.87"
      ry="31.25"
    />
    <circle cx="407.59" cy="359.39" r="7.27" fill="#af6e3e" />
  </svg>
)

export { Craft }
