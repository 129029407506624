/* eslint-disable react/prop-types */
import React from 'react'

const Cook = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Cook"
    viewBox="0 0 720 720"
  >
    <text
      fill="#af6e3e"
      fontFamily="Roboto-Thin, Roboto"
      fontSize="97"
      fontWeight="200"
      transform="translate(334.67 387.95)"
    >
      it
    </text>
    <path fill="#f3ddce" d="M-.35-.39h720v720h-720z" />
    <path
      fill="#af6e3e"
      d="M508.19 342.41h-14.71v-17.54h-257v17.54h-15.4a10.78 10.78 0 00-10.78 10.78v5.41a10.78 10.78 0 0010.78 10.78h15.4v55.17c0 18.7 18.3 33.86 40.88 33.86H452.6c22.58 0 40.88-15.16 40.88-33.86v-55.17h14.71A10.78 10.78 0 00519 358.6v-5.41a10.78 10.78 0 00-10.81-10.78zM387.4 289.56l4.85-14.36c-.18-7.95-12.29-14.38-27.22-14.4s-27.07 6.36-27.27 14.3l6 14.42c-60.68 2.29-106.94 14.22-106.94 28.61h256.3c.03-14.29-45.63-26.13-105.72-28.57z"
    />
  </svg>
)

export { Cook }
