import React from 'react'

const Heading3 = props => {
  return (
    <h3
      className="mt-4 text-xl leading-7 font-normal uppercase text-cocoa"
      {...props}
    />
  )
}

export default Heading3
