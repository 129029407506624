/* eslint-disable react/prop-types */
import React from 'react'

const MakesCard = ({ children }) => {
  return (
    <div className="bg-white w-full overflow-hidden rounded-lg shadow-lg">
      {children}
    </div>
  )
}

export default MakesCard
