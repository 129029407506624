import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import Heading2 from './elements/heading2'
import Paragraph from './elements/paragraph'

function Bio() {
  return (
    <StaticQuery
      query={bioQuery}
      render={data => {
        const { author, social } = data.site.siteMetadata
        return (
          <section className="relative mx-auto pt-16 pb-24 px-4 sm:px-6 lg:px-8 lg:py-20 flex flex-col md:flex-row items-center">
            <div className="lg:flex-shrink-0">
              <Image
                className="h-64 w-64 rounded-full xl:h-80 xl:w-80"
                fluid={data.avatar.childImageSharp.fluid}
                alt={author}
              />
            </div>
            <div className="mt-10 md:mt-0 md:ml-10 prose prose-lg text-center">
              <h2>HEY, I'M LINDSEY!</h2>
              <p>
                I’m a mama, wife, and creator who currently resides just outside
                of Seattle, WA with my husband Kyle and daughter Isla. Welcome
                to my creative corner of the Internet, I’m so glad you’re here!
              </p>
            </div>
          </section>
        )
      }}
    />
  )
}

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/FamilyPhoto.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 720) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    site {
      siteMetadata {
        author
      }
    }
  }
`

export default Bio
