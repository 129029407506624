/* eslint-disable react/prop-types */
import React from 'react'
import { Link } from 'gatsby'
import Image from 'gatsby-image'
import Heading2 from '../elements/heading2'
import Paragraph from '../elements/paragraph'

const LatestsPostsGrid = ({ posts }) => {
  return (
    <div className="mx-auto mt-10 container max-w-5xl px-4 sm:px-6 md:px-8 pb-14 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug
        return (
          <Link
            key={node.fields.slug}
            to={node.fields.slug}
            className="mx-auto max-w-11/12 md:max-w-5/12 lg:max-w-1/3  rounded-lg shadow-lg overflow-hidden flex flex-col"
          >
            <Image
              className="flex-shrink-0 max-h-72"
              fluid={node.frontmatter.featuredImage.childImageSharp.fluid}
              alt={node.frontmatter.featuredImageAlt}
            />
            <div className="flex-1 bg-white p-5 sm:p-6 flex flex-col justify-start">
              <div className="-mb-3">
                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-powder text-cocoa">
                  {node.frontmatter.category}
                </span>
              </div>
              {/* <p className="text-sm leading-5 font-medium text-powder">
                {node.frontmatter.category}
              </p> */}
              <span className="whitespace-normal break-words">
                <Heading2>{title}</Heading2>
              </span>

              <Paragraph
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.featureText,
                }}
              />
            </div>
          </Link>
        )
      })}
    </div>
  )
}

export default LatestsPostsGrid
